import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injector } from '@angular/core';
import { AppComponent } from './app.component';
import { createCustomElement } from '@angular/elements';
import { PopoverModule  } from 'ngx-bootstrap/popover';
import { HttpClientModule } from '@angular/common/http';
import { FileService } from './services/crud.services';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
// import { RouterModule, Routes } from '@angular/router';

// const appRoutes: Routes = [
//   {
//     path : '',
//     component : AppComponent
//   }
  
// ];
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    PopoverModule.forRoot(),
    TooltipModule.forRoot(),
    HttpClientModule,
  ],
  entryComponents:[
    AppComponent
  ],
  providers: [
    FileService
  ],
  bootstrap: [
    // AppComponent
    // ,
    // RouterModule
  ]
})
export class AppModule {
  constructor( private injector: Injector){
  }
  ngDoBootstrap (){
    const el = createCustomElement(AppComponent, {injector: this.injector});
    customElements.define('autopi-pload',el);
  }
 }
