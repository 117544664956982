// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const baseURL="https://api.automatapi.com";

// const baseURL="http://localhost:8080";

export const environment = {
  production: false,
  statemachineroot: baseURL + "/flow-api",
  apiurl : "https://api.automatapi.com",
  flow : "/api/flow/flowintegration",
  interfaceService: baseURL + '/flow/interfaces',
  fileUploadData: baseURL + '/flow-api/fileUpload/fileUploadData',
  saveFileUploadData : baseURL + '/flow-api/fileUpload/fileUploadData',
  graphobjectbyflowinstanceid: 'console/statemachine/graphobject',
  graphobjectDataPoints: 'console/statemachine/graphobjectDataPoints',
  //getting users and hierarchy
  getuser:'console/user/',
  
  validateApiKey: baseURL+'/flow/validateApiKey',

  //task operations
  statebyid: 'console/state/',
  getDocuments: 'console/state/documents',
  // fileUploadUrl: baseURL+'/api/uploadData',
  fileUploadUrl: baseURL+'/api/uploadFile',
  fileDeleteUrl: baseURL+'/api/deleteDataFile',
  //accesscontrol
  accesscontrol:'console/accesscontrol/',
  fetchaccess: 'access/',

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.