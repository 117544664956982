export const commonKeys = {};
export class UrlModel {
    downloadFileUrl : string;
    fileName : string;
    fullDataUrl : string;
    fullFileUrl : string;
    url : string

    constructor(){
        this.downloadFileUrl = null;
        this.fileName = null;
        this.fullDataUrl = null;
        this.fullFileUrl = null;
        this.url  = null;
    }
};
export class FileUploadStructure{
    uploadTime : number;
    documentType : string;
    fileType : string;
    desc : string;
    sampleUrl : string;
    validationApi : string;
    fileSize : string;
    verify : boolean;
    userFileName : string;
    url : any;
    errorMessage : string;
    payload : any;
    functionInstanceName : string;

    constructor(){
        this.uploadTime = null;
        this.documentType = null;
        this.fileType = null;
        this.desc = null;
        this.sampleUrl = null;
        this.validationApi = null;
        this.fileSize = null;
        this.verify = null;
        this.userFileName = null;
        this.url = null;
        this.errorMessage = null;
        this.payload = null;
        this.functionInstanceName = null;
  }
};
export class FileUpload extends FileUploadStructure{

    _id : string;
    name : string;
    stageCd : string;
    companyId : string;
    validFrom : string;
    validTill : string;
    machineType : string;
    entityId : string;
    payload : any;
    flowTriggered : boolean;
    createdTime : number;
    updatedTime : number;
    documents : FileUploadStructure[];
    utmMedium : string;
    utmCampaign : string;
    utmTerm : string;
    saveApiResponse : boolean;

    constructor() {
        super();
        this._id = null;
        this.name = null;
        this.stageCd = null;
        this.companyId = null;
        this.validFrom = null;
        this.validTill = null;
        this.machineType = null;
        this.entityId = null;
        this.payload = {};
        this.flowTriggered = null;
        this.createdTime = null;
        this.updatedTime = null;
        this.documents = [];
        this.utmMedium = null;
        this.utmCampaign = null;
        this.utmTerm = null;
        this.saveApiResponse = null;
    }
};