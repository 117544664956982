import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
  })
  export class FileService {
    constructor(
      // private router: Router,
      private httpClient: HttpClient,
      private sanitizer: DomSanitizer
    ) { }
    getJwtToken(apiKey : string): Observable<any>{
      const subject = new Subject<any>();
      let request = {
        "apiKey":apiKey
      };
      // var request = new FormData();
      // request.append('apiKey',apiKey);
      let url = `${environment.validateApiKey}`;
      console.log(JSON.stringify(request));
      const httpHeaders = new HttpHeaders({
        'Content-Type': 'application/json'});
      this.httpClient.post<any>(
        url,
        request,
        {
          observe: 'response',
          reportProgress: true,
          headers: httpHeaders
        }
      ).subscribe(
        (response: HttpResponse<any>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );
      return subject.asObservable();
    }
    upload(request: FormData,authToken : string): Observable<any> {
      const subject = new Subject<any>();
      const url = `${environment.fileUploadUrl}`;
      const httpHeaders = new HttpHeaders({
        'Authorization': "Bearer "+authToken
    });
      if (request) {
        this.httpClient.post<any>(
          url,
          request,
          {
            observe: 'response',
            reportProgress: true,
            headers: httpHeaders
          }
        ).subscribe(
          (response: HttpResponse<any>) => {
            if (response.body) {
              subject.next(response.body);
            }
          },
          (err: HttpErrorResponse) => {
            // All errors are handled in ErrorInterceptor, no further handling required
            // Unless any specific action is to be taken on some error
  
            subject.error(err);
          }
        );
      } else {
        subject.error('File is null or empty');
      }
  
      return subject.asObservable();
    }
  
    download(filePath: string, authToken :string,getAsFileUrl?: boolean ): Observable<any> {
      const subject = new Subject<any>();
      const url = `${filePath}`;
      const httpHeaders = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer "+authToken
    });
    var substring = "api.automatapi";
    var requestBody;
    if (url.includes(substring)){
    requestBody = {
      observe: 'response',
      reportProgress: true,
      responseType: "blob" as "json",
      headers:httpHeaders
    }}
    else{
      requestBody = {
          observe: 'response',
          reportProgress: true,
          responseType: "blob" as "json"
    }}
      this.httpClient.get<any>(
        url,
        requestBody
      ).subscribe(
        (response: HttpResponse<any>) => {
          if (response.body) {
            if (response && response.body) {
              let urlParts = filePath.split("/");
              let fileName = urlParts && urlParts.length > 0 && urlParts[urlParts.length - 1] ? urlParts[urlParts.length - 1] : 'temp';
              let url = URL.createObjectURL(response.body);
              
              let link = document.createElement("a");
              link.setAttribute("href", url);
              link.setAttribute("download", fileName);
              link.style.visibility = 'hidden';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
    
              subject.next(true);
            } else {
              console.log('No reponse found for file download request');
              subject.error('Failed to download file, please try again later.');
            }
            // subject.next(response.body);
            // if (getAsFileUrl) {
            //   const reader = new FileReader();
            //   reader.addEventListener("load", () => {
            //     subject.next(reader.result);
            //   }, false);
  
            //   reader.readAsDataURL(response.body);
            // } else {
            //   let urlParts = filePath.split("/");
            //   let fileName = urlParts && urlParts.length > 0 && urlParts[urlParts.length - 1] ? urlParts[urlParts.length - 1] : 'temp';
            //   FileSaver.saveAs(response.body, fileName);
            // }
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error
          subject.error(err);
        }
      );
  
      return subject.asObservable();
    }
    delete(filePath: string,authToken: string): Observable<any> {
      const subject = new Subject<any>();
      const url = `${environment.fileDeleteUrl}`+filePath;
      const httpHeaders = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer "+authToken,
    });
    this.httpClient.delete<any>(
      url,
      {
        observe: 'response',
        reportProgress: true,
        headers:httpHeaders
      }
    ).subscribe(
      (response: HttpResponse<any>) => {
        subject.next(response.body);
      },
      (err: HttpErrorResponse) => {
        subject.next(err);
      }
    );
    return subject.asObservable();
    }
    getFileDetails(_id: String,authToken : string): Observable<any>{
      const subject = new Subject<any>();
      const url = `${environment.fileUploadData}`+ "/" +_id;
      const httpHeaders = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer "+authToken
    });

      if (_id) {

        this.httpClient.get<any>(
          url,
          {
            observe: 'response',
            reportProgress: true,
            headers : httpHeaders,
          },
        ).subscribe(
          (response: HttpResponse<any>) => {
            if (response.body) {
              subject.next(response.body);
            }
          },
          (err: HttpErrorResponse) => {
            // All errors are handled in ErrorInterceptor, no further handling required
            // Unless any specific action is to be taken on some error
  
            subject.error(err);
          }
        );


      } else {
        subject.error('Cannot fetch data. Service expired.');
      }
      return subject.asObservable();
    }

    saveRecord(formData: any,authToken : string): Observable<any>{
      const subject = new Subject<any>();
      const url = `${environment.saveFileUploadData}`;
      const httpHeaders = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer "+authToken
    });
      
      if (formData) {

        this.httpClient.post<any>(
          url,
          formData,
          {
            observe: 'response',
            reportProgress: true,
            headers : httpHeaders
          },
        ).subscribe(
          (response: HttpResponse<any>) => {
            if (response.body) {
              subject.next(response.body);
            }
          },
          (err: HttpErrorResponse) => {
            // All errors are handled in ErrorInterceptor, no further handling required
            // Unless any specific action is to be taken on some error
  
            subject.error(err);
          }
        );


      } else {
        subject.error('Cannot fetch data. Service expired.');
      }
      return subject.asObservable();
    }

    verifyDoc(formData : any,validationApi: string,authToken : string): Observable<any>{
      const subject = new Subject<any>();
      const url = `${environment.apiurl}`+"/"+validationApi;
      const httpHeaders = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer "+authToken
    });

      if (formData){


        this.httpClient.post<any>(

          url,
          formData,
          {
            observe: 'response',
            reportProgress: true,
            headers : httpHeaders,
          },
        ).subscribe(
          (response: HttpResponse<any>) => {
            if (response.body) {
              subject.next(response.body);
            }
            
          },
          (err: HttpErrorResponse) => {
            // All errors are handled in ErrorInterceptor, no further handling required
            // Unless any specific action is to be taken on some error
  
            subject.error(err);
            
          }
        )
      }
      return subject.asObservable();
  }

  initiateFlow(formData,payload,authToken : string): Observable<any> {
    const subject = new Subject<any>();
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': "Bearer "+authToken
  });
    let url = `${environment.apiurl}${environment.flow}`;
    let body = {
      'payload': payload,
      'operation': 'UPDATE',
      'machineType': formData.machineType,
      'entityId': formData.entityId,
    };

    this.httpClient.post<any>(
        url,
        body,
        {
            headers: httpHeaders,
            observe: 'response',
            reportProgress: true,
            withCredentials: true
        }
    ).subscribe(
        (response: HttpResponse<any>) => {
            if (response && response.body) {
                subject.next(response.body);
            } else {
                subject.next(response);
            }
        },
        (err: HttpErrorResponse) => {
            
            subject.error(err);
        }
    );

    return subject.asObservable();
}
}