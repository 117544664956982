import { analyzeAndValidateNgModules } from '@angular/compiler';
import { Component, Input, OnInit, OnChanges, SimpleChanges, AfterViewInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { FileUpload, UrlModel } from './model/common.model'
import { FileService } from './services/crud.services';
import { error } from '@angular/compiler/src/util';
import { Subscription } from 'rxjs';
@Component({
  selector: 'api-fileUpload',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() apikey: string;
  @Input() refid: string;
  authtoken: string;
  isList: boolean = true;
  indexDoc = -1;
  file: any;
  disableUploadButton = true;
  fileSizeErrorMsg = "";
  fileUploadModel: any;
  doc: any[];
  documents: any[];
  url: any;
  flowResponse: any;
  response: any;
  fileUpload: FileUpload;
  document: Document;
  urls: UrlModel;
  fileSize: string;
  userFileName: string;
  machineType: string;
  flowTriggered: boolean;
  subscription: Subscription;
  fileSizeErrorMsgList: string[];
  stageCd: string;
  draftStage: string = "DRAFT";
  constructor(
    public cd: ChangeDetectorRef,
    private crudService: FileService,
  ) {
    this.fileUpload = new FileUpload();
    this.document = new Document();
    this.urls = new UrlModel();
  }
  ngAfterViewInit(): void {
    this.cd.detectChanges();
    this.crudService.getJwtToken(this.apikey).subscribe(
      (response) => {
        if (response.success) {
          this.authtoken = response.jwt;
          this.crudService.getFileDetails(this.refid, this.authtoken).subscribe(
            (response) => {
              const fileupload: FileUpload = response.success;
              // if (fileupload && fileupload.stageCd=="DRAFT"){
              this.fileUpload = fileupload;
              this.documents = this.fileUpload.documents;
              this.machineType = this.fileUpload.machineType;
              this.flowTriggered = this.fileUpload.flowTriggered;
              this.stageCd = this.fileUpload.stageCd;
              this.cd.detectChanges();
              // }
              // else {
              //   this.showToast('error', 'This link is no longer valid.', 'top-right','',3000);
              // }
            }

          );
        }
      }
    );

  }


  ngOnInit(): void {
    this.cd.detectChanges();
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.cd.detectChanges();
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    // 
  }
  toggleGrid(flag) {
    this.isList = flag;
    this.cd.detectChanges();
  }
  title = 'fileUploadElement';

  validFileSize(event, item) {
    if (this.fileUpload.stageCd === "DRAFT") {
      let docType = item.documentType;
      for (let index = 0; index < this.fileUpload.documents.length; index++) {
        const element = this.fileUpload.documents[index];
        if (element.documentType == docType) {
          this.indexDoc = index;
          break;
        }
      }
      if (this.fileUpload.documents[this.indexDoc].url == null) {
        var files = event.target.files;
        this.file = files[0];
        var FileSize = this.file.size / 1024 / 1024;
        var FileName = this.file.name;
        if (FileSize > 5 || !FileName) {
          this.fileSizeErrorMsg = "File size should be less than or equal to 5 MB.";
          this.fileUpload.documents[this.indexDoc].errorMessage = this.fileSizeErrorMsg;
          this.showToast('error', this.fileSizeErrorMsg, 'top-right', '', 3000);
        }
        else if (!FileName.includes(".")) {
          this.fileSizeErrorMsg = "File should have extension.";
          this.fileUpload.documents[this.indexDoc].errorMessage = this.fileSizeErrorMsg;
          this.showToast('error', this.fileSizeErrorMsg, 'top-right', '', 3000);
          // other file type extensions check need to be added
        }
        else {
          this.disableUploadButton = false;
          this.fileSizeErrorMsg = null;
          this.fileUpload.documents[this.indexDoc].errorMessage = this.fileSizeErrorMsg;
          this.fileSize = FileSize.toString();
          this.userFileName = FileName;
          this.uploadFile();
        }
      }
    }
    else {
      this.showToast('error', 'This page is no longer editable. The documents are already submitted. You can only view the documents.', 'top-right', '', 3000);
    }
  }

  uploadFile() {
    if (this.file) {
      var fileInputForm = new FormData();
      fileInputForm.append('file', this.file, this.userFileName);
      fileInputForm.append("fileName", this.userFileName);
      fileInputForm.append("functionInstanceName", "FILEUPLOAD");
      let entityType = "FILEUPLOAD_UNKNOWNTYPE";
      if (this.fileUpload.documents[this.indexDoc].documentType) {
        entityType = this.fileUpload.documents[this.indexDoc].documentType.split(" ").join("");
      }
      fileInputForm.append("entityType", "FILEUPLOAD_" + entityType);
      fileInputForm.append("entityRef", this.fileUpload._id);
      this.crudService.upload(fileInputForm, this.authtoken).subscribe(
        response => {
          // // this.spinner.hide()
          if (response && response['downloadFileUrl']) {
            this.urls = response;
            this.fileUpload.documents[this.indexDoc].url = this.urls;
            this.fileUpload.documents[this.indexDoc].userFileName = this.userFileName;
            this.fileUpload.documents[this.indexDoc].fileSize = this.fileSize;
            this.validateApiFunction();
          }
        },
        error => {
          this.fileUpload.documents[this.indexDoc].errorMessage = "Error in file upload. Please try again.";
          this.showToast('error', 'Error in file upload. Please try again.', 'top-right', '', 3000);
          // this.spinner.hide();

          // return error;

        }
      );
    }
  }
  validateApiFunction() {
    // let docType = this.fileUpload.documents[this.indexDoc].documentType;
    // if (docType==null || docType==""){
    //   var num = this.indexDoc;
    //   var sNum = num.toString();
    //   docType = "document"+sNum;
    // }
    // let documentPayload = {};
    // let typeOfPayload = typeof this.fileUpload.payload;
    // if (typeOfPayload=="object"){
    //   documentPayload = this.fileUpload.payload;
    // }
    if (this.fileUpload.documents[this.indexDoc].validationApi) {

      let request = {
        "functionInstanceName": this.fileUpload.documents[this.indexDoc].functionInstanceName,
        "url": this.fileUpload.documents[this.indexDoc].url
      };
      if (this.fileUpload.documents[this.indexDoc].fileType) {
        request["fileType"] = this.fileUpload.documents[this.indexDoc].fileType
      }
      this.crudService.verifyDoc(request, this.fileUpload.documents[this.indexDoc].validationApi, this.authtoken).subscribe(
        response => {
          if (response) {
            if (response.valid != null) {
              this.fileUpload.documents[this.indexDoc].verify = response.valid;
            }
            const size = new TextEncoder().encode(JSON.stringify(response)).length
            const kbSize = size / 1024
            if (this.fileUpload.saveApiResponse || kbSize < 1000) {
              this.fileUpload.documents[this.indexDoc].payload = response;
            }
            if (kbSize >= 1000) {
              this.fileUpload.documents[this.indexDoc].payload = { "message": "Api Response size exceeded 1MB hence removed from payload" };
            }
            if (response.payload == null && response.error_message) {
              this.fileUpload.documents[this.indexDoc].errorMessage = JSON.stringify(response.error_message);
              this.showToast('error', response.error_message, 'top-right', '', 3000);
            }
            this.saveFileResponse(this.fileUpload);
          }
          else {
            this.fileUpload.documents[this.indexDoc].verify = false;
            this.fileUpload.documents[this.indexDoc].payload = {};
            this.saveFileResponse(this.fileUpload);
          }
        },
        error => {
          this.fileUpload.documents[this.indexDoc].verify = false;
          this.fileUpload.documents[this.indexDoc].payload = error;
          this.saveFileResponse(this.fileUpload);
        }
      );
    }
    else {
      this.fileUpload.documents[this.indexDoc].verify = true;
      this.fileUpload.documents[this.indexDoc].payload = {};
      this.saveFileResponse(this.fileUpload);
    }
  }
  saveFileResponse(fileUpload) {
    this.subscription = this.crudService.saveRecord(fileUpload, this.authtoken).subscribe(
      response => {
        var fileUploadResponse = new FileUpload();
        fileUploadResponse = response;
        if (fileUploadResponse && fileUploadResponse.documents[this.indexDoc].url != null) {
          this.fileUpload = fileUploadResponse;
          this.stageCd = this.fileUpload.stageCd;
          this.cd.detectChanges();
        }
        else {
          this.fileUpload.documents[this.indexDoc].url = null;
          this.fileUpload.documents[this.indexDoc].verify = false;
          this.fileUpload.documents[this.indexDoc].payload = {};
          this.fileUpload.documents[this.indexDoc].userFileName = null;
          this.fileUpload.documents[this.indexDoc].fileSize = null;
          this.fileUpload.documents[this.indexDoc].errorMessage = "Error in file upload. Please try again.";
          this.showToast('error', 'Error in file upload. Please try again.', 'top-right', '', 3000);
          this.cd.detectChanges();
        }
      },
      error => {
        this.fileUpload.documents[this.indexDoc].url = null;
        this.fileUpload.documents[this.indexDoc].verify = false;
        this.fileUpload.documents[this.indexDoc].payload = {};
        this.fileUpload.documents[this.indexDoc].userFileName = null;
        this.fileUpload.documents[this.indexDoc].fileSize = null;
        this.fileUpload.documents[this.indexDoc].errorMessage = "Error in file upload. Please try again.";
        console.log(error);
        this.showToast('error', 'Error in file upload. Please try again.', 'top-right', '', 3000);
        this.cd.detectChanges();
      }
    );
    this.cd.detectChanges();
    this.ngOnDestroy();
  }
  openModal() {
    var modal = document.querySelector(".modal");
    var container = modal.querySelector(".container");
    modal.classList.remove("hidden")
  }
  closeModal() {
    var modal = document.querySelector(".modal");
    var container = modal.querySelector(".container");
    modal.classList.add("hidden")
  }
  triggerFlow() {
    if (!this.fileUpload.flowTriggered) {
      if (this.fileUpload.machineType && !this.fileUpload.flowTriggered) {
        var documents = this.fileUpload.documents;
        var flag = true;
        // This checks if all the files have been uploaded
        // for (var i = 0; i < documents.length; i++) {
        //   if (documents[i].url == null) {
        //     flag = false;
        //   }
        // }
        if (flag) {
          let payload = JSON.stringify({ "documents": this.fileUpload.documents, "fileUploadId": this.fileUpload._id })
          const size = new TextEncoder().encode(payload).length
          const kbSize = size / 1024
          console.log(kbSize.toString())
          if (kbSize >= 1000) {
            for (var i = 0; i < documents.length; i++) {
              delete documents[i].payload
            }
            payload = JSON.stringify({ "documents": documents, "fileUploadId": this.fileUpload._id, "message": "Api Response of all the files is deleted as payload size exceeded 1MB." })
          }
          // console.log(payload)
          this.flowResponse = this.crudService.initiateFlow(this.fileUpload, payload, this.authtoken)
            .subscribe(
              result => {
                this.fileUpload.flowTriggered = true;
                this.saveFlowResponse(this.fileUpload);
                this.closeModal();
              },
              error => {
                this.fileUpload.flowTriggered = false;
                console.log(error);
                return error;
              }
            );
        }
        else {
          this.showToast('error', 'Please upload all the documents before submitting.', 'top-right', '', 3000);
        }
      }
    }
    else {
      this.showToast('error', 'This page is no longer editable. The documents are already submitted. You can only view the documents.', 'top-right', '', 3000);
    }
  }
  showErrorIfAny(message) {
    if (message != null && message != undefined) {
      let messages = (message.replace("[", "")).replace(']', '').split('","');
      console.log(messages)
      let msg = "";
      for (let index = 0; index < messages.length; index++) {
        const element = (messages)[index];
        msg += element;
      }
      return msg;
    } else {
      return "";
    }

  }
  saveFlowResponse(fileUpload) {
    this.crudService.saveRecord(fileUpload, this.authtoken).subscribe(
      response => {
        var fileUploadResponse = new FileUpload();
        fileUploadResponse = response;
        if (fileUploadResponse && fileUploadResponse.flowTriggered) {
          this.fileUpload = fileUploadResponse;
          this.cd.detectChanges();
          this.showToast('info', 'Submitted.', 'top-right', '', 3000);
        }
        else {
          this.fileUpload.flowTriggered = false;
          this.cd.detectChanges();
          this.showToast('error', 'Error while submitting. Please click on Submit button again.', 'top-right', '', 3000);
          this.cd.detectChanges();
        }
      },
      error => {
        this.fileUpload.flowTriggered = false;
        console.log(error);
        this.cd.detectChanges();
        this.showToast('error', 'Error while submitting. Please click on Submit button again.', 'top-right', '', 3000);
        this.cd.detectChanges();
      }
    );
    this.cd.detectChanges();
    this.ngOnDestroy();
  }

  downloadFile(item) {
    let docType = item.documentType;

    for (let index = 0; index < this.fileUpload.documents.length; index++) {

      const element = this.fileUpload.documents[index];

      if (element.documentType == docType) {

        this.indexDoc = index;
        break;
      }
    }
    this.urls = this.fileUpload.documents[this.indexDoc].url;
    if (this.urls && this.urls.fullFileUrl) {
      let fullFileUrl = this.urls.fullFileUrl.replace("http", "https");
      this.crudService.download(fullFileUrl, this.authtoken, true).subscribe(
        response => {
          // if (window.navigator && window.navigator.msSaveBlob){
          //   window.navigator.msSaveBlob(response,this.fileUpload.documents[this.indexDoc].userFileName);
          // }
          // else {
          // const fileObjectURL = URL.createObjectURL(response);        
          // window.open(fileObjectURL) ;
          //  }
        },
        error => {
          console.log(error);
        }

      );
    }

  }

  deleteFile(item) {
    console.log(this.stageCd);
    console.log(this.fileUpload.stageCd);
    if (this.fileUpload.stageCd === "DRAFT") {
      let docType = item.documentType;

      for (let index = 0; index < this.fileUpload.documents.length; index++) {

        const element = this.fileUpload.documents[index];

        if (element.documentType == docType) {

          this.indexDoc = index;
          break;
        }
      }
      this.urls = this.fileUpload.documents[this.indexDoc].url;

      if (this.urls && this.urls.fullFileUrl) {
        // let docType = this.fileUpload.documents[this.indexDoc].documentType;
        // if (docType==null || docType==""){
        //   var num = this.indexDoc;
        //   var sNum = num.toString();
        //   docType = "document"+sNum;
        // }
        let fullFileUrl = this.urls.fullFileUrl;
        let splitedUrl = fullFileUrl.split("/api/downloadDataFile");

        if (splitedUrl && splitedUrl.length > 1) {
          this.crudService.delete(splitedUrl[1], this.authtoken).subscribe(
            response => {
              if (response && response.result) {
                this.fileUpload.documents[this.indexDoc].url = null;
                this.fileUpload.documents[this.indexDoc].verify = false;
                this.fileUpload.documents[this.indexDoc].payload = {};
                this.fileUpload.documents[this.indexDoc].errorMessage = null;
                this.fileUpload.documents[this.indexDoc].userFileName = null;
                this.fileUpload.documents[this.indexDoc].fileSize = null;
                this.saveDeleteFileResponse(this.fileUpload);
              }
              else {
                //add not deleted toastr
                this.showToast('error', 'Error while deleting. Please click on Delete again.', 'top-right', '', 3000);
              }
            },
            error => {
              console.log(error);
              //add not deleted toastr
              this.showToast('error', 'Error while deleting. Please click on Delete again.', 'top-right', '', 3000);
            }
          );
        }
      }
    }
    else {
      this.showToast('error', 'This page is no longer editable. The documents are already submitted. You can only view the documents.', 'top-right', '', 3000);
    }

  }
  saveDeleteFileResponse(fileUpload) {
    this.subscription = this.crudService.saveRecord(fileUpload, this.authtoken).subscribe(
      response => {
        var fileUploadResponse = new FileUpload();
        fileUploadResponse = response;
        if (fileUploadResponse && fileUploadResponse.documents[this.indexDoc].url != null) {
          this.fileUpload = fileUploadResponse;
          this.cd.detectChanges();
          this.showToast('info', 'Deleted.', 'top-right', '', 3000);
          this.cd.detectChanges();
        }
        else {
          // ??
          this.cd.detectChanges();
          this.showToast('info', 'Deleted.', 'top-right', '', 3000);
          this.cd.detectChanges();
        }
      },
      error => {
        // ??
        console.log(error);
        this.cd.detectChanges();
        this.showToast('info', 'Deleted.', 'top-right', '', 3000);
        this.cd.detectChanges();
      }
    );
    this.cd.detectChanges();
    this.ngOnDestroy();
  }

  showFile(item) {
    if (item.sampleUrl && item.sampleUrl != "") {
      let docType = item.documentType;
      for (let index = 0; index < this.fileUpload.documents.length; index++) {
        const element = this.fileUpload.documents[index];
        if (element.documentType == docType) {
          this.indexDoc = index;
          break;
        }
      }
      if (this.fileUpload.documents[this.indexDoc].sampleUrl) {
        let fullFileUrl = this.fileUpload.documents[this.indexDoc].sampleUrl.replace("http:", "https:");
        this.crudService.download(fullFileUrl, this.authtoken, true).subscribe(
          response => {
            // if (window.navigator && window.navigator.msSaveBlob){
            //   window.navigator.msSaveBlob(response,this.fileUpload.documents[this.indexDoc].userFileName);
            // }
            // else {
            // const fileObjectURL = URL.createObjectURL(response);        
            // window.open(fileObjectURL) ;
            //  }
          },
          error => {
            console.log(error);
          });
      }
    } else {
      this.showToast('info', 'No Reference File Uploaded.', 'top-right', '', 3000);
    }
  }
  showToastFlag: boolean = false;
  toastMsg = {
    pos: "toast-top-left",
    title: "",
    type: "toast-info",
    message: ""
  };
  showToast(type, message, position?, heading?, forTime?) {
    let pos, title, timeout;
    if (position) { this.toastMsg.pos = "toast-" + position }
    if (!forTime) { timeout = 3000 } else { timeout = forTime; }
    if (heading) { this.toastMsg.title = heading }
    this.toastMsg.type = "toast-" + type;
    this.toastMsg.message = message;
    this.showToastFlag = true;
    this.cd.detectChanges();
    setTimeout(() => {
      this.toastMsg = {
        pos: "toast-top-left",
        title: "",
        type: "toast-info",
        message: ""
      };
      this.showToastFlag = false;
      this.cd.detectChanges()
    }, timeout);
  }
}
